@use "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.switcher {
  width: 128px;

  :global(.languageSwitcher__value-container .languageSwitcher__single-value) {
    margin-right: 8px;
    float: right;
  }

  :global(.languageSwitcher__control) {
    border: 0 !important;
    box-shadow: none !important;
    font-size: 14px;
    font-weight: 400;
    height: 36px;
    padding: 0 10px;
    transition: none !important;
    width: 128px;

    :global(.fi-jp) {
      border: 0.5px solid colors.$backgroundHoverColor;
    }
  }

  :global(.languageSwitcher__menu) {
    border: 0 !important;
    color: colors.$ColorPrimaryBlue !important;
    border-radius: 3px !important;
    padding: 10px 0;
    max-width: 116px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1) !important;
  }

  :global(.Select .Select-menu-wrapper .Select-menu-list-option .languageSwitcher__option) {
    display: flex;
    justify-content: flex-start;
    height: 36px;
    width: 116px;
    line-height: 20px;
    padding: 8px 20px;
    color: colors.$ColorPrimaryBlue !important;
    font-weight: 400;
    border-radius: 3px !important;

    :global(.fi-jp) {
      margin-top: 3px;
      height: 14px;
      border: 0.5px solid colors.$backgroundHoverColor;
    }

    &:hover {
      background-color: #f3f3f3ff !important;
      color: colors.$ColorPrimaryBlueHover !important;
    }
  }

  :global(.Select .Select-menu-wrapper .Select-menu-list-option.is-selected .languageSwitcher__option) {
    color: colors.$ColorPrimaryBlue !important;
    font-weight: 400;

    &:after {
      content: none;
    }
  }
}
